import React from 'react'
import { FaLinkedinIn } from 'react-icons/fa'
import { BsGlobe2 } from 'react-icons/bs'

import ibrahim from '../../assets/images/team/1.webp'
import bilal from '../../assets/images/team/2.webp'
import ali from '../../assets/images/team/3.webp'
import saud from '../../assets/images/team/4.webp'

let data = [
  {
    images: ibrahim,
    title: 'Ibrahim Arif',
    designation: 'Team Leader',
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: 'https://www.linkedin.com/in/ibrahim-arif/',
      },
      {
        icon: <BsGlobe2 />,
        url: 'https://ibrahimarif.dev/',
      },
    ],
  },
  {
    images: bilal,
    title: 'Bilal Naeem',
    designation: 'Sr. Software Engineer',
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: 'https://www.linkedin.com/in/muhammad-bilal-dev/',
      },
    ],
  },
  {
    images: ali,
    title: 'Ali Shahzaib',
    designation: 'UI/UX Designer',
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: 'https://www.linkedin.com/in/ali-shahzaib-7788a8245/',
      },
    ],
  },
  {
    images: saud,
    title: 'Saud ul Hassan',
    designation: 'Software Engineer',
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: 'https://www.linkedin.com/in/saud-ul-hassan-711960212/',
      },
    ],
  },
]

export default data
