import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from 'react-router-dom'

const PortfolioList1 = [
  {
    id: 'wobo',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fdark_card.webp?alt=media&token=3b2601cd-ed0b-4005-a5db-48c97db83eea',
    title: 'WOBO',
    description:
      'A platform to connect athletes with local home gyms, boxing rings and basketball venues.',
  },
  {
    id: 'the-green-felts',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/TGF%20-%20Card%20Dark.webp?alt=media&token=5402e248-06db-48b6-9921-8cb7254dcff4',
    title: 'The Green Felts',
    description:
      'For effortless golf league management, real-time score tracking, and live leaderboards.',
  },

  {
    id: 'dressplaner',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fdark_card.webp?alt=media&token=1584cfe5-1bb0-4c9b-ad67-46e3ff0a0361',
    title: 'DRESSPLANER',
    description:
      'A digital solution to streamline B2B and B2C fashion operations.',
  },
  {
    id: 'base-reward-wallet',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/BRW%20-%20Card%20Dark.webp?alt=media&token=cccbca7d-c70c-4f1f-a9f6-581482ee3cdc',
    title: 'Base Reward Wallet',
    description:
      'Secure crypto management and seamless blockchain services across Africa.',
  },
  {
    id: 'servicehub',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcard.webp?alt=media&token=6f8a4b5e-573f-4300-a37a-e6452505eb63',
    title: 'ServiceHub',
    description:
      'A marketplace connecting users with trusted providers for easy booking.',
  },
  {
    id: 'heurica',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fdark.webp?alt=media&token=29c298d8-180a-44d2-81b2-b27bc0fe8b1a',
    title: 'Heurica',
    description:
      'To connects international PhD candidates with ideal research collaborators.',
  },
  {
    id: 'veteye',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fdark_card.webp?alt=media&token=d0ab38c8-570e-4e22-a6e1-0909e30fa4c1',
    title: 'VetEye',
    description:
      'Veterinary education app with disease images, quizzes, and lectures.',
  },
  {
    id: 'wealth-wise',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2Fdark_card.webp?alt=media&token=f8a09dcb-d9b0-428a-88ee-8c7c1d63b815',
    title: 'Wealth Wise',
    description:
      'A personal finance app for tracking income, expenses, and investments.',
  },
  {
    id: 'easy-as-pie-budget',
    image:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fdark_card_2.webp?alt=media&token=0000324f-c5b0-4dd6-b2b8-a693c4262fa6',
    title: 'Easy As Pie Budget',
    description:
      'Makes managing personal and group expenses simple and efficient.',
  },
]

class TabStyleThree extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { column } = this.props

    const Cards = ({ list }) => (
      <div className="profolio-card-container">
        {list.map((value, index) => (
          <Link to={`/portfolio/${value?.id}`} key={index}>
            <div className="profolio-card">
              <div className="profolio-img-container">
                <img src={value?.image} />
              </div>
              <div className="protfolio-data">
                <h3 className="title">{value.title}</h3>
                <p style={{ fontSize: 16, color: 'black', minHeight: 90 }}>
                  {value.description}
                </p>

                <div className="portfolio-button" style={{ marginTop: '5%' }}>
                  <a className="rn-btn" href="/portfolio">
                    Case Study
                  </a>
                </div>
              </div>
            </div>
          </Link>
        ))}{' '}
      </div>
    )

    return (
      <div>
        <Tabs>
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="tablist-inner">
                <TabList className="pv-tab-button text-center mt--0">
                  <Tab>
                    <span className="tab-text">All Project</span>
                  </Tab>
                  {/* <Tab>
                    <span className="tab-text">Mobile App</span>
                  </Tab>
                  <Tab>
                    <span className="tab-text">Web Design</span>
                  </Tab> */}
                </TabList>
              </div>
            </div>
          </div>

          <TabPanel>
            <Cards list={PortfolioList1} />
          </TabPanel>

          {/* <TabPanel>
            <Cards list={PortfolioList1} />
          </TabPanel> */}
        </Tabs>
      </div>
    )
  }
}

export default TabStyleThree
