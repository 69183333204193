import { FaFacebookF, FaLinkedinIn, FaAppStoreIos } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa6'
import { BsGlobe2 } from 'react-icons/bs'
import { GrInstagram } from 'react-icons/gr'
import { FiChevronUp } from 'react-icons/fi'
import { IoLogoGooglePlaystore } from 'react-icons/io5'

export default {
  wobo: {
    id: 'wobo',
    name: 'WOBO',
    descriptions: [
      '',
      'WOBO is a versatile app that enhances the fitness experience for athletes by offering private home gyms, boxing rings, and basketball courts. Athletes can book venues easily and enjoy a peaceful, personalized workout environment. The app features secure payment options and provides motivational audio messages, called DRIPS, to keep users engaged and inspired.',
      "For merchants, WOBO streamlines venue management, pricing, and bookings with integrated Stripe Connect for seamless payments. Merchants can also record and sell DRIPS to engage clients. The app's comprehensive tools support venue management, feedback collection, and real-time communication.",
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2023 - Present',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'Sports & Fitness',
      },
    ],
    social_links: [
      // {
      //   Social: <GrInstagram />,
      //   link: '',
      // },
      // {
      //   Social: <FaLinkedinIn />,
      //   link: '',
      // },
      // {
      //   Social: <FaFacebookF />,
      //   link: '',
      // },
      // { Social: <BsGlobe2 />, link: 'https://wobo.world/' },
    ],
    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2Fheader.webp?alt=media&token=29ecb87d-b43e-47f0-9b69-d0cac1f9c74d',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F1.webp?alt=media&token=5d228ce4-c836-4d3a-bf7a-9ac465adcbee',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F2.webp?alt=media&token=169806f2-34db-4bef-a26c-3b2d35bb8e80',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F3.webp?alt=media&token=a904abf2-66b9-4bd0-a358-fc85c79e0acd',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F4.webp?alt=media&token=0b2ad47b-c4af-4ece-af02-1114d8109280',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F5.webp?alt=media&token=489433dd-c26b-4f5a-b0fb-c49012c5edc2',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F6.webp?alt=media&token=b1e80be0-3af5-4adc-819d-f23cbe01e160',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F7.webp?alt=media&token=20f149f0-3c47-4404-af74-5542b26682ae',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F8.webp?alt=media&token=8d9aefb8-88f0-41fd-8411-1b0d9763a3b9',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F9.webp?alt=media&token=2e5c117e-1680-4367-b5ac-2f7f2a1cd49d',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F10.webp?alt=media&token=28a60d9b-e6b8-40d2-8ae5-6c176eee9650',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fcasestudy%2F11.webp?alt=media&token=a3f520c6-368d-4cb0-b345-82f5eeef14a0',
    ],
    related_projects: [
      {
        id: 'base-reward-wallet',
        title: 'Base Reward Wallet',
        descriptions:
          'Secure crypto management and seamless blockchain services across Africa.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Foverview.webp?alt=media&token=a3e1d943-5bdf-4379-94de-698cfe03c4d6',
      },
      {
        id: 'easy-as-pie-budget',
        title: 'Easy As Pie Budget',
        descriptions:
          'Makes managing personal and group expenses simple and efficient.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Foverview.webp?alt=media&token=8a1b9f52-0839-4309-baf1-38029f6b0ef9',
      },
    ],
    apple_store_link: 'https://apps.apple.com/us/app/wobo/id6475769960',
    play_store_link:
      'https://play.google.com/store/apps/details?id=com.wobo.android',
  },
  'the-green-felts': {
    id: 'the-green-felts',
    name: 'The Green Felts',
    descriptions: [
      '',
      'The Green Felts mobile app offers an all-in-one solution providing easy event creation and participant management for various golfing styles like MatchPlay, Stroke Play, and Stableford. With real-time score tracking, automatic winner calculations, and live leaderboards, the app ensures a seamless and engaging experience for both players and administrators. Additionally, it supports league management, live updates, and convenient announcements, making it an indispensable tool for modern golf club operations.',
      'Designed to streamline golf league and match management. Create leagues, track scores in real-time, and access live leaderboards effortlessly for a seamless golfing experience.',
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2022 - Present',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'Sports',
      },
    ],
    social_links: [
      {
        Social: <GrInstagram />,
        link: 'https://www.instagram.com/thegreenfelts/',
      },
      {
        Social: <FaLinkedinIn />,
        link: 'https://www.linkedin.com/company/the-green-felts/',
      },
      {
        Social: <FaFacebookF />,
        link: 'https://www.facebook.com/TheGreenFelts/',
      },
      { Social: <BsGlobe2 />, link: 'https://thegreenfelts.com/' },
    ],
    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F1.webp?alt=media&token=0a48013a-04fb-4b36-975b-fde09f76c553',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F2.webp?alt=media&token=d58f5e5a-79d0-429e-94c5-fe699c3e7beb',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F3.webp?alt=media&token=ca4c052d-2e9c-4c13-89e0-7a7bc2a78440',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F44.webp?alt=media&token=cf7db67b-5150-4a39-a166-3032d5a9d884',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F55.webp?alt=media&token=629a6a0f-fdf6-47ac-823e-f87e84e97fad',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F66.webp?alt=media&token=32e9baa3-0a50-4853-9b57-b23b743b36b0',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F77.webp?alt=media&token=85e542b9-25ff-4592-9d41-90aa9bc3067a',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2F88.webp?alt=media&token=77b55f8b-a028-4802-9418-8f75e327c3c0',
    ],
    related_projects: [
      {
        id: 'wobo',
        title: 'WOBO',
        descriptions:
          'A platform to connect athletes with local home gyms, boxing rings and basketball venues.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fintro.webp?alt=media&token=6a48094e-e616-4dcb-9802-21cfd9782a1e',
      },
      {
        id: 'easy-as-pie-budget',
        title: 'Easy As Pie Budget',
        descriptions:
          'Makes managing personal and group expenses simple and efficient.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Foverview.webp?alt=media&token=8a1b9f52-0839-4309-baf1-38029f6b0ef9',
      },
    ],
    apple_store_link:
      'https://apps.apple.com/ie/app/the-green-felts/id1639790835',
    play_store_link:
      'https://play.google.com/store/apps/details?id=com.thegreenfelts.android',
  },
  'base-reward-wallet': {
    id: 'base-reward-wallet',
    name: 'Base Reward Wallet',
    descriptions: [
      '',
      'Base Reward is transforming the blockchain landscape in Africa with a mobile app that integrates Shop, Play, Trade, and Earn features, promoting the adoption of cryptocurrency. The app offers both custodial and non-custodial wallet services, allowing users to securely manage and trade cryptocurrencies like BRW, Bitcoin, and Ethereum, along with prepaid card options for daily transactions.',
      "Beyond typical wallet functions, Base Reward includes a buyback and burn program to increase token value and rewards. It also connects users to various online services, enabling them to earn tokens and make secure purchases within the BRW ecosystem. By merging blockchain with gaming and building Africa's largest P2P marketplace, Base Reward drives innovation and growth in the continent's digital economy.",
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2023',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'FinTech',
      },
    ],
    social_links: [
      {
        Social: <FaLinkedinIn />,
        link: 'https://www.linkedin.com/company/base-reward/',
      },
      {
        Social: <FaYoutube />,
        link: 'https://www.youtube.com/channel/UCwGhxoP3URH---QSnfF7XoQ',
      },
      { Social: <BsGlobe2 />, link: 'https://stage.basereward.online/' },
    ],
    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2Ftop_banner.webp?alt=media&token=4e46a67c-cec3-446f-86b3-bbd789d4ab34',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F1.webp?alt=media&token=20407499-4ce9-42e3-91a5-725fd62c12b2',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F2.webp?alt=media&token=18e068cc-856c-4816-8cb2-80e996173cc6',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F3.webp?alt=media&token=fa5d0f3b-26be-4184-9ed6-deec34b2b560',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F4.webp?alt=media&token=581a4559-41e9-46d2-a978-dd644d8e0c96',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F5.webp?alt=media&token=0d33f001-dbf8-4f5c-8a50-e170d67d5066',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F6.webp?alt=media&token=67622f91-a8c0-417b-84bb-824d215565ac',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F7.webp?alt=media&token=dcce71a2-7d61-4378-ba7a-307c8ad357f1',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F8.webp?alt=media&token=a6ef95d4-b06c-4d48-b155-d6e8f2fa2919',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F9.webp?alt=media&token=ce03f935-2fea-4ed0-9ac3-49f77dfd90a3',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F10.webp?alt=media&token=2343acc5-551a-4b30-a163-803976ac01dd',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F11.webp?alt=media&token=f7ca6341-d237-4f32-b0c3-838e28238fa7',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F12.webp?alt=media&token=61f8e0f6-0583-4f2d-bd8a-ffbaf555bd41',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Fcase%20study%2F13.webp?alt=media&token=d117df7b-e0ef-48f5-9089-9ccf58b6f6c8',
    ],
    related_projects: [
      {
        id: 'wobo',
        title: 'WOBO',
        descriptions:
          'A platform to connect athletes with local home gyms, boxing rings and basketball venues.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fintro.webp?alt=media&token=6a48094e-e616-4dcb-9802-21cfd9782a1e',
      },
      {
        id: 'easy-as-pie-budget',
        title: 'Easy As Pie Budget',
        descriptions:
          'Makes managing personal and group expenses simple and efficient.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Foverview.webp?alt=media&token=8a1b9f52-0839-4309-baf1-38029f6b0ef9',
      },
    ],
  },
  heurica: {
    id: 'heurica',
    name: 'Heurica',
    descriptions: [
      '',
      'Heurica is a mobile app designed to enhance global academic collaboration by connecting PhD candidates with the right research collaborators. Through Heurica, users can create detailed profiles for their research projects, specifying the qualifications and skills they seek in collaborators. Heurica fosters community engagement by allowing users to browse, review, like, and comment on various projects.',
      'Heurica also features in-app messaging for direct communication between researchers, ensuring seamless collaboration. With advanced AI to monitor content integrity and an admin panel to manage reports and support requests, Heurica provides a secure and productive environment for academic partnerships.',
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2024',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'EdTech',
      },
    ],
    social_links: [
      {
        Social: <FaLinkedinIn />,
        link: 'https://www.linkedin.com/company/heurica/',
      },
      {
        Social: <GrInstagram />,
        link: 'https://www.instagram.com/heurica_app/',
      },
      { Social: <BsGlobe2 />, link: 'https://heurica-app.com/' },
    ],
    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F0.webp?alt=media&token=39a065eb-adfa-467f-b519-e8d1e06db838',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F1.webp?alt=media&token=d460eb4d-a3c7-4314-9af9-fdd068df1074',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F2.webp?alt=media&token=cd5454cc-5157-4315-98b6-59373066a847',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F3.webp?alt=media&token=de6aa614-9bd1-4352-83e0-91a80d78688b',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F4.webp?alt=media&token=9d52d1b4-5470-42ec-b843-31699cc39446',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F5.webp?alt=media&token=13bce24e-745b-4189-9fd0-86c195a2d196',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F6.webp?alt=media&token=4fd48b24-8dbb-4a3a-985f-b6738e2ce659',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F7.webp?alt=media&token=c60e3716-7cfe-4ddc-804e-7484bdd7da5d',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Fcasestudy%2F8.webp?alt=media&token=6b478a6c-6de6-4c12-9421-1bac1f1ac8d5',
    ],
    related_projects: [
      {
        id: 'veteye',
        title: 'VetEye',
        descriptions:
          'Veterinary education app with disease images, quizzes, and lectures.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Foverview.webp?alt=media&token=04d27fe5-8516-4f60-9102-e63fea81c02b',
      },
      {
        id: 'the-green-felts',
        title: 'The Green Felts',
        descriptions:
          'For effortless golf league management, real-time score tracking, and live leaderboards.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/The%20Green%20Felts%2FCase%20Study%2FMask%20group.webp?alt=media&token=8b6bce97-d40c-49a9-922c-0d17d501600d',
      },
    ],
    play_store_link:
      'https://play.google.com/store/apps/details?id=com.heurica.android',
    apple_store_link: 'https://apps.apple.com/jp/app/heurica/id6503301072',
  },
  'easy-as-pie-budget': {
    id: 'easy-as-pie-budget',
    name: 'Easy As Pie Budget',
    descriptions: [
      '',
      'Easy As Pie Budget is a user-friendly app designed to streamline personal and group expense management. It simplifies budgeting with intuitive tools that let you set and track budgets, categorize expenses, and monitor your financial goals. Whether managing finances alone, with a partner, or as a family, the app provides real-time feedback to help you stay on top of your spending and make informed financial decisions.',
      'With features like customizable expense categories, support for recurring expenses, and goal-setting for savings and debt elimination, Easy As Pie Budget adapts to your unique financial needs. Its straightforward interface ensures that budgeting remains effortless, making it easier to achieve your financial objectives and maintain a balanced budget.',
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2022 - Present',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'Personal Finance',
      },
    ],
    social_links: [
      {
        Social: <FaLinkedinIn />,
        link: 'https://www.linkedin.com/company/easy-as-pie-budget/',
      },
      { Social: <BsGlobe2 />, link: 'https://easyaspiebudget.com/' },
    ],
    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2Fheader.webp?alt=media&token=030e2a49-a81e-473c-a11e-b9558333fa53',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F1.webp?alt=media&token=3bacf6f9-8e54-4744-b2cf-7b61ac51299f',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F2.webp?alt=media&token=9d57a4ab-6726-4c1c-9c2e-8132d15f38ae',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F3.webp?alt=media&token=5101115a-c2c7-4e02-8c48-09680f6402bc',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F4.webp?alt=media&token=4429bc8d-e5e3-4166-8eb9-af3b1a331607',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F5.webp?alt=media&token=e791b5b1-e3f2-4b31-bff9-589214a30662',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F6.webp?alt=media&token=99031ef3-b0cb-496e-b0af-3ee50e52a802',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F7.webp?alt=media&token=bd6e792e-a67a-4478-8850-9b23d396d781',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F8.webp?alt=media&token=bf72a7f0-8b4a-45fb-b8b9-872e366b7aa1',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F9.webp?alt=media&token=a3b33b93-9fe1-4aa1-93a4-801e75f5ff59',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Fcase%20study%2F10.webp?alt=media&token=db8cb5c1-490d-46e6-a05c-bbdbb3a716fe',
    ],
    related_projects: [
      {
        id: 'wealth-wise',
        title: 'Wealth Wise',
        descriptions:
          'A personal finance app for tracking income, expenses, and investments.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2Foverview.png?alt=media&token=35109dfe-928c-4a17-bde2-63b9bc4a582c',
      },
      {
        id: 'base-reward-wallet',
        title: 'Base Reward Wallet',
        descriptions:
          'Secure crypto management and seamless blockchain services across Africa.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Foverview.webp?alt=media&token=a3e1d943-5bdf-4379-94de-698cfe03c4d6',
      },
    ],
    play_store_link:
      'https://play.google.com/store/apps/details?id=com.easyaspie.app',
    apple_store_link:
      'https://apps.apple.com/vc/app/easy-as-pie-budget/id1665444739',
  },
  veteye: {
    id: 'veteye',
    name: 'VetEye',
    descriptions: [
      '',
      'A mobile app for the Animal Eye Institute, designed to enhance veterinary ophthalmology education. The app provides students with a comprehensive library of eye disease images and case studies, complete with detailed descriptions and advanced filtering options. Students can favorite essential posts for easy future access, making study sessions more targeted and effective.',
      'VetEye also features interactive quizzes tailored for board exam preparation and a selection of expert-led video lectures. Students can purchase and view these lectures, with certificates issued upon completion. Additionally, the app allows users to reset and reattempt quizzes, ensuring optimal preparation and progress tracking.',
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2024',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'EdTech',
      },
    ],
    social_links: [
      {
        Social: <GrInstagram />,
        link: 'https://www.instagram.com/animaleyeinstitute/',
      },
      {
        Social: <FaFacebookF />,
        link: 'https://www.facebook.com/AnimalEyeInstitute',
      },
      {
        Social: <FaLinkedinIn />,
        link: 'https://www.linkedin.com/in/dj-haeussler-jr-67230b39/',
      },
      // {
      //   Social: <FaAppStoreIos />,
      //   link: '',
      // },
      // {
      //   Social: <IoLogoGooglePlaystore />,
      //   link: '',
      // },
      { Social: <BsGlobe2 />, link: 'https://www.animaleyeinstitute.com/' },
    ],
    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2Ftop_header.webp?alt=media&token=d4e023bc-1f94-45a4-aa35-f526f5de4e08',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2F1.webp?alt=media&token=96250a98-a566-4481-9b6a-91b72fb75c2e',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2F2.webp?alt=media&token=5f0755ef-8657-4230-bccd-e804d1b0aa54',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2F3.webp?alt=media&token=88e95b3b-6ea6-40e6-88e1-5164ec5360c2',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2F4.webp?alt=media&token=61e8f7ab-9081-45ad-9a79-b0381ee5657a',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2F5.webp?alt=media&token=418b10c7-3120-491f-903e-8e3438d8f99b',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2F6.webp?alt=media&token=9165e1f2-aa2b-45c0-890f-f4f5e39a81d9',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2F7.webp?alt=media&token=87fc6036-c3ad-4d1e-9cbb-c4348c3045e4',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/VetEye%2Fcasestudy%2F8.webp?alt=media&token=f249e8c8-6a26-4675-bcac-3428aad0c67b',
    ],
    related_projects: [
      {
        id: 'heurica',
        title: 'Heurica',
        descriptions:
          'To connects international PhD candidates with ideal research collaborators.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Foverview.webp?alt=media&token=1dddcc68-2683-4b5e-8293-0a9440bf1545',
      },
      {
        id: 'base-reward-wallet',
        title: 'Base Reward Wallet',
        descriptions:
          'Secure crypto management and seamless blockchain services across Africa.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Foverview.webp?alt=media&token=a3e1d943-5bdf-4379-94de-698cfe03c4d6',
      },
    ],
    play_store_link:
      'https://play.google.com/store/apps/details?id=com.veteye.android',
    apple_store_link: 'https://apps.apple.com/app/veteye/id6593688499',
  },
  'wealth-wise': {
    id: 'wealth-wise',
    name: 'Wealth Wise',
    descriptions: [
      '',
      'Wealth Wise is a robust personal finance app designed to help users manage their finances effortlessly. Users can set monthly incomes, categorize expenses, and track spending in real time. With seamless bank account integration via the Plaid API, the app automatically captures transactions from debit and credit cards, providing an accurate view of financial activities.',
      'The app also streamlines bill management with due date reminders and features a stock portfolio tracker that offers real-time market updates to assess net worth. Wealth Wise provides a free trial with flexible subscription options through RevenueCat APIs, and push notifications keep users informed of essential financial updates and reminders.',
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2024',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'Personal Finance',
      },
    ],

    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2FCase%20Study%2Fheader.webp?alt=media&token=d28eabf3-c0da-4adf-9d57-a0589d1c0038',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2FCase%20Study%2F1.webp?alt=media&token=be9a8cb4-4f7e-4baf-8534-994608b15ad9',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2FCase%20Study%2F2.webp?alt=media&token=314a6513-b12e-4428-83a7-d0a2ec875cf1',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2FCase%20Study%2F3.webp?alt=media&token=16472a56-2169-4c91-a070-82ec38ec0ca8',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2FCase%20Study%2F4.webp?alt=media&token=4cc560e8-b18b-4d17-8c3a-9ccb2449c28c',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2FCase%20Study%2F5.webp?alt=media&token=83f04cf8-c349-41da-8dfd-1b178702b66c',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2FCase%20Study%2F6.webp?alt=media&token=ca40d3d2-e4a7-4d55-b569-04d268fffc3d',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Wealth%20Wise%2FCase%20Study%2F7.webp?alt=media&token=75eb4985-ec69-4c2b-b792-8573591179ca',
    ],
    related_projects: [
      {
        id: 'easy-as-pie-budget',
        title: 'Easy As Pie Budget',
        descriptions:
          'Makes managing personal and group expenses simple and efficient.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Foverview.webp?alt=media&token=8a1b9f52-0839-4309-baf1-38029f6b0ef9',
      },
      {
        id: 'base-reward-wallet',
        title: 'Base Reward Wallet',
        descriptions:
          'Secure crypto management and seamless blockchain services across Africa.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Foverview.webp?alt=media&token=a3e1d943-5bdf-4379-94de-698cfe03c4d6',
      },
    ],
  },
  dressplaner: {
    id: 'dressplaner',
    name: 'DRESSPLANER',
    descriptions: [
      '',
      "DRESSPLANER, a fashion company in Germany, aimed to optimize their B2B and B2C operations through digitization. They sell trendy women's clothing, shoes, and accessories to over 1,000 resellers across Europe, who then distribute products to end customers. To improve efficiency and partner engagement, DRESSPLANER needed a solution for simplified order management and a better customer experience.",
      'We developed mobile apps for administrators, partners, and customers, along with a dynamic website, to meet these needs. These tools streamlined product management, automated order processing, and provided a seamless shopping experience, enabling DRESSPLANER to scale their business and increase sales in the European fashion market.',
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2022 - Present',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'B2B e-commerce',
      },
    ],
    social_links: [
      {
        Social: <FaFacebookF />,
        link: 'https://www.facebook.com/dressplaner',
      },
      {
        Social: <FaLinkedinIn />,
        link: 'https://www.linkedin.com/company/dressplaner',
      },
      { Social: <BsGlobe2 />, link: 'https://dressplaner.de/' },
    ],
    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2Ftop_header.webp?alt=media&token=4d6ef076-d327-4644-aee7-5e298fcaab09',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F2.webp?alt=media&token=ba028f84-1318-4fd9-833a-dccb080057c4',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F3.webp?alt=media&token=8b8690fa-ce2e-4451-ae3a-88cd53bd472b',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F4.webp?alt=media&token=419bd2af-b2ad-4397-99fd-2a76fc70b00d',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F5.webp?alt=media&token=54e9a32c-7069-4cd4-b7bd-abfe46ed0df5',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F6.webp?alt=media&token=6a46831b-163d-4a9b-b0f7-6ed6fcc94a25',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F7.webp?alt=media&token=4ab2052d-8c8a-4f49-9ec5-ddca82499116',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F8.webp?alt=media&token=9b7c96ff-bfab-44cd-99e1-d18306f8e9a8',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F9.webp?alt=media&token=2f76dd1b-8465-409c-b2e4-d1dc8ed034ef',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F10.webp?alt=media&token=3cd6e233-b424-47fe-986e-d8f5f31e7a59',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F11.webp?alt=media&token=4e954417-26d1-44e3-9e32-876281161243',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F12.webp?alt=media&token=6bffda75-a2e2-4db2-a6a8-8726d9ba0529',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F13.webp?alt=media&token=be4aa6de-fa34-4146-bf27-819491fa34fd',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F14.webp?alt=media&token=7fbb6d07-62c4-487a-9324-0c403a59206d',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/DRESSPLANER%2Fcasestudy%2F15.webp?alt=media&token=73c93bbc-c959-4ca7-957e-9cc40e46957b',
    ],
    related_projects: [
      {
        id: 'heurica',
        title: 'Heurica',
        descriptions:
          'To connects international PhD candidates with ideal research collaborators.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Heurica%2Foverview.webp?alt=media&token=1dddcc68-2683-4b5e-8293-0a9440bf1545',
      },
      {
        id: 'base-reward-wallet',
        title: 'Base Reward Wallet',
        descriptions:
          'Secure crypto management and seamless blockchain services across Africa.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Base%20Reward%20Wallet%2Foverview.webp?alt=media&token=a3e1d943-5bdf-4379-94de-698cfe03c4d6',
      },
    ],
    play_store_link:
      'https://play.google.com/store/apps/details?id=com.dressplaner.android',
    apple_store_link: 'https://apps.apple.com/de/app/dressplaner/id6444239937',
  },
  servicehub: {
    id: 'servicehub',
    name: 'ServiceHub',
    descriptions: [
      '',
      '⚠️ Users often struggle to find reliable service providers due to unverified information, inconsistent reviews, and complicated booking processes. At the same time, service providers face difficulties in promoting their services, managing appointments, and building trust with potential clients.',
      '💡 ServiceHub provides a centralized platform where users can easily discover, book, and review trusted service providers across multiple industries. With real-time notifications, a smart calendar system, and seamless communication tools, the app enhances user experience while helping providers efficiently manage their services and reach more customers.',
    ],
    bullet_points: [
      {
        title: 'Year',
        value: '2025',
      },
      {
        title: 'Project Types',
        value: 'Mobile App Development',
      },
      {
        title: 'Industry',
        value: 'Service Marketplace',
      },
    ],
    social_links: [
      // {
      //   Social: <GrInstagram />,
      //   link: '',
      // },
      // {
      //   Social: <FaLinkedinIn />,
      //   link: '',
      // },
      // {
      //   Social: <FaFacebookF />,
      //   link: '',
      // },
      // { Social: <BsGlobe2 />, link: 'https://wobo.world/' },
    ],
    top_banner:
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcasestudy%2Ftop_header.webp?alt=media&token=082f9edf-770f-4a74-aa09-ae488ec319bc',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcasestudy%2F1.webp?alt=media&token=d075440e-c5d3-49e4-912b-482ab007dcee',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcasestudy%2F2.webp?alt=media&token=361f12d4-b51d-4e63-85f0-b3e7f805e600',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcasestudy%2F3.webp?alt=media&token=54535639-e86b-4be9-b4a5-84280a3b12e6',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcasestudy%2F4.webp?alt=media&token=69fe1593-7853-4c84-9150-fae4ed9c3e3b',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcasestudy%2F5.webp?alt=media&token=b85516d2-467e-4d5a-9ade-96f22705d7f7',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcasestudy%2F6.webp?alt=media&token=c049dc68-55ea-4ca2-988b-20dffa4253ce',
      'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/ServiceHub%2Fcasestudy%2F7.webp?alt=media&token=90a6e357-b67c-4c78-b3c3-dc071d6c12f3',
    ],
    related_projects: [
      {
        id: 'wobo',
        title: 'WOBO',
        descriptions:
          'A platform to connect athletes with local home gyms, boxing rings and basketball venues.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/WOBO%2Fintro.webp?alt=media&token=6a48094e-e616-4dcb-9802-21cfd9782a1e',
      },
      {
        id: 'easy-as-pie-budget',
        title: 'Easy As Pie Budget',
        descriptions:
          'Makes managing personal and group expenses simple and efficient.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/infilp.appspot.com/o/Easy%20As%20Pie%20Budget%2Foverview.webp?alt=media&token=8a1b9f52-0839-4309-baf1-38029f6b0ef9',
      },
    ],
    apple_store_link:
      'https://apps.apple.com/us/app/ang-servicehub/id6740162111',
    play_store_link:
      'https://play.google.com/store/apps/details?id=com.servicehub.android',
  },
}
